import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import profile from 'src/store/profile';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  InputLabel,
  FormControl,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Modal,
  TableContainer,
  TablePagination,
  Link,
  Select,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Студент', alignRight: false },
  { id: 'task', label: 'Завдання', alignRight: false },
  { id: 'answer', label: 'Відповідь', alignRight: false },
  { id: 'isVerified', label: 'Дата', alignRight: false },
  /* { id: 'status', label: 'Status', alignRight: false }, */
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ChatPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [tasks, setTasks] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(false);

  const [openImage, setOpenImage] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const navigate = useNavigate();

  const getTasks = () => {
    fetch(
      `https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/message-api?expand=student,lesson&sort=-id&filter[type]=5&filter[group_id]=${group}`,
      {
        crossDomain: true,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setTasks(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    if (profile.isTeacher)
      fetch(
        `https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/sgroup-api?filter[date_end][neq]='0000-00-00'&filter[teacher_id]=${profile.data.id}`,
        {
          crossDomain: true,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            setGroups(result);
            if (result.length) setGroup(result[0].id);
          },
          (error) => {
            console.log(error);
          }
        );
  }, []);

  useEffect(() => {
    if (group) {
      getTasks();
    }
  }, [group]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tasks.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tasks.length) : 0;

  const filteredUsers = applySortFilter(tasks, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Завдання | Lala English </title>
      </Helmet>

      <Modal
        open={openImage}
        onClose={() => setOpenImage(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack alignItems="center" justifyContent="center">
          <img src={modalImage} alt="modal lala" />
        </Stack>
      </Modal>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Група</InputLabel>
            <Select
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              value={group}
              label="Група"
              onChange={(event) => setGroup(event.target.value)}
            >
              {groups.map((value) => {
                return (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tasks.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, text, date, course_id: courseid, lesson_id: lessonid, student, lesson } = row;
                    const selectedUser = selected.indexOf(text) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack alignItems="center" spacing={2}>
                            <Avatar alt={'tt'} src={'/assets/images/avatars/avatar_2.jpg'} />
                            <Link
                              component="button"
                              variant="subtitle2"
                              color="#333"
                              sx={{ textAlign: 'left' }}
                              onClick={() => navigate('/teacher/profile', { state: row.student })}
                            >
                              {student.name}
                            </Link>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Stack alignItems="left" spacing={1}>
                            <div>
                              Edmap {lesson.edmap} {lesson.name}
                            </div>
                            {lesson.task}
                            <img
                              src={lesson.image}
                              alt={lesson.task}
                              onClick={() => {
                                setOpenImage(true);
                                setModalImage(lesson.image);
                              }}
                              onKeyDown={() => {
                                setOpenImage(true);
                                setModalImage(lesson.image);
                              }}
                              style={{ cursor: 'pointer' }}
                              type="submit"
                            />
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{text}</TableCell>

                        <TableCell align="left">{date}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tasks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
