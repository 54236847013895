import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import profile from 'src/store/profile';
// @mui
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Link,
  Snackbar,
  Alert,
} from '@mui/material';
import { CreateFeedback, CreateMessage } from 'src/sections/@dashboard/student';
import { MessageNews } from 'src/sections/@dashboard/app';
// components
import Resizer from 'react-image-file-resizer';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function ProfilePage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [alert, setAlert] = useState(false);
  const [messages, setMessages] = useState([]);
  const [student] = useState(profile.isTeacher ? location.state : profile.data);
  const [image, setImage] = useState(profile.avatar);

  const getMessages = () => {
    setIsOpen(false);
    fetch(
      `https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/message-api?expand=lesson&sort=-id&filter[student_id]=${student.id}&filter[or][0][type]=0&filter[or][1][type]=5`,
      {
        crossDomain: true,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setMessages(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const saveAvatar = (img) => {
    const storedJson = JSON.stringify(img);
    console.log('saveAvatar', storedJson);
    localStorage.setItem('Avatar', storedJson);
    profile.setAvatar(img);
  };

  const readAvatar = () => {
    const storedJson = JSON.parse(localStorage.getItem('Avatar'));
    if (storedJson !== undefined) {
      setImage(storedJson);
      profile.setAvatar(storedJson);
    }
  };

  useEffect(() => {
    readAvatar();
    getMessages();
  }, []);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const changeHandler = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const img = await resizeFile(file);
      setImage(img);
      console.log('changeHandler', img);
      saveAvatar(img);
    }
  };

  return (
    <>
      <Helmet>
        <title> Профіль | Lala English </title>
      </Helmet>

      <Container maxWidth="xl">
        {profile.isTeacher ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={() => navigate(-1)} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
              Назад
            </Button>
            <Button
              onClick={() => setOpenMessage(true)}
              variant="outlined"
              startIcon={<Iconify icon="eva:email-fill" />}
            >
              Повідомлення / рекомендація
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="flex-end" justifyContent="end" mb={5}>
            <Box
              component="div"
              sx={{
                height: 40,
                display: 'inline-flex',
              }}
            >
              <img src={'/assets/images/prizes/dream-big-animation-9.png'} alt={'lala english svg'} />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => setOpenMessage(true)}
              variant="outlined"
              startIcon={<Iconify icon="eva:email-fill" />}
            >
              Надіслати повідомлення вчителю
            </Button>
          </Stack>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title={'Студент'} />

              <CardContent>
                <Stack alignItems="center" sx={{ mb: 2 }}>
                  <Avatar sx={{ width: 96, height: 96, alignSelf: 'center' }} src={image} />
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    align="center"
                    sx={{ mt: 1 }}
                  >
                    Новий аватар
                    <VisuallyHiddenInput type="file" onChange={changeHandler} accept="image/*" />
                  </Button>
                </Stack>

                <Typography variant="subtitle2" align="center">
                  {student.name}
                </Typography>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Stack spacing={0.5}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Особиста траекторія: <Link>pdf файл</Link>
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Email: {student.email}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Контакт: {student.phone}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Статус: активний
                  </Typography>
                </Stack>

                {profile.isTeacher ? (
                  <>
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    <Stack spacing={1}>
                      <Button
                        onClick={() => setIsOpen(true)}
                        variant="contained"
                        fullWidth
                        startIcon={<Iconify icon="eva:people-fill" />}
                      >
                        Залишити фідбек батькам
                      </Button>
                    </Stack>
                  </>
                ) : null}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <MessageNews
              title={profile.isTeacher ? "Зв'язок зі студентом" : "Зв'язок із вчителем"}
              list={messages.map((message, lesson) => ({
                id: message.id,
                title: message.teacher_id === message.student_id ? 'Студент:' : 'Вчитель:',
                description: message.text,
                image: message.teacher_id === message.student_id ? image : '/assets/images/covers/cover_5.jpg',
                postedAt: message.date,
                link: message.link,
                lesson: message.lesson // `Edmap ${message.lesson.edmap} ${message.lesson.name}`
              }))}
            />
          </Grid>
          <Snackbar open={alert} autoHideDuration={4000} onClose={() => setAlert(false)}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              Успішно відправлено!
            </Alert>
          </Snackbar>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
            <DialogTitle>
              {' '}
              <Typography>Фідбек батькам</Typography>
            </DialogTitle>
            <DialogContent>
              <CreateFeedback
                student={student}
                success={() => {
                  setIsOpen(false);
                  setAlert(true);
                }}
              />
            </DialogContent>
          </Dialog>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Dialog onClose={() => setOpenMessage(false)} open={openMessage}>
            <DialogTitle>
              <Typography>Надіслати повідомлення</Typography>
            </DialogTitle>
            <DialogContent>
              <CreateMessage
                type={0}
                student={student}
                success={() => {
                  getMessages();
                  setOpenMessage(false);
                  setAlert(true);
                }}
              />
            </DialogContent>
          </Dialog>
        </Stack>
      </Container>
    </>
  );
}
