import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const titles = {
  '/teacher/intro': 'Відео-інструкції',
  '/teacher/groups': 'Групи студентів',
  '/teacher/chats': 'Завдання',
  '/teacher/user': 'Студенти',
  '/teacher/courses': 'Курси',
  '/teacher/calendar': 'Розклад',
  '/teacher/products': 'Уроки',
  '/teacher/profile': 'Профіль студента',
  '/student/profile': 'Профіль',
  '/student/intro': 'Правила',
  '/student/homeworks': 'Уроки',
  '/student/media': 'Мої завантаження',
  '/student/info': 'Lala info',
  '/student/day': 'Щоденник',
  '/student/selfstudy': 'Уроки self study',
  '/demo/blog': 'Картки',
  '/demo/app': 'Статистика',
};

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const location = useLocation();
  console.log(location);
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Typography variant="h4" sx={{ mb: 0, color: '#666' }}>
          {titles[location.pathname]}
        </Typography>

        {/* <Box sx={{ flexGrow: 1 }} />

        <Box
          component="div"
          sx={{
            height: 40,
            display: 'inline-flex',
          }}
        >
          <img src={'/assets/images/prizes/dream-big-animation-9.png'} alt={'lala english svg'} />
        </Box> */}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
