import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/teacher/UserPage';
import GroupPage from './pages/teacher/GroupPage';
import ChatPage from './pages/teacher/ChatPage';
import ProductsPage from './pages/teacher/ProductsPage';
import CoursesPage from './pages/teacher/CoursesPage';
import CalendarPage from './pages/teacher/CalendarPage';
import IntroPage from './pages/teacher/IntroPage';

import BlogPage from './pages/demo/BlogPage';
import DashboardAppPage from './pages/demo/DashboardAppPage';

import IntroStudPage from './pages/student/IntroStudPage';
import SelfstudyPage from './pages/student/SelfstudyPage';
import ProfilePage from './pages/student/ProfilePage';
import Homeworks from './pages/student/Homeworks';
import MediaPage from './pages/student/MediaPage';
import DayPage from './pages/student/DayPage';
import InfoPage from './pages/student/InfoPage';

import LoginPage from './pages/LoginPage';
import LoginPageST from './pages/LoginPageST';
import Page404 from './pages/Page404';
import Page404teacher from './pages/teacher/Page404teacher';
import Page404student from './pages/student/Page404student';

import profile from './store/profile';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    profile.role === 2 ? {
      path: '/teacher',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/teacher/products" />, index: true },
        { path: 'intro', element: <IntroPage /> },
        { path: 'groups', element: <GroupPage /> },
        { path: 'chats', element: <ChatPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'courses', element: <CoursesPage /> },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'info', element: <InfoPage /> },
        {
          element: <SimpleLayout />,
          children: [
            { path: '/teacher/*', element: <Navigate to="/teacher/404" /> },
            { path: '/teacher/404', element: <Page404teacher /> },
          ],
        },
      ],
    } : profile.role === 1 ?
      {
        path: '/student',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/student/profile" />, index: true },
          { path: 'profile', element: <ProfilePage /> },
          { path: 'selfstudy', element: <SelfstudyPage /> },
          { path: 'intro', element: <IntroStudPage /> },
          { path: 'homeworks', element: <Homeworks /> },
          { path: 'media', element: <MediaPage /> },
          { path: 'day', element: <DayPage /> },
          { path: 'info', element: <InfoPage /> },
          {
            element: <SimpleLayout />,
            children: [
              { path: '/student/*', element: <Navigate to="/student/404" /> },
              { path: '/student/404', element: <Page404student /> },
            ],
          },
        ],
      } : { path: '/student/*', element: <Navigate to="/student/login" /> },
    {
      path: '/demo',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/demo/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '/teacher/login',
      element: <LoginPage />,
    },
    {
      path: '/student/login',
      element: <LoginPageST />,
    },
    {
      element: <SimpleLayout />,
      children: [
        /* { element: <Navigate to="/student/login" />, index: true }, */
        { path: '/404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
