// @mui
import PropTypes from 'prop-types';
import { Avatar, Modal, Box, Stack, Link, Card, Typography, CardHeader } from '@mui/material';
import { useState } from 'react';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

MessageNews.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function MessageNews({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      {!list.length ? (
        <Typography variant="body2" sx={{ p: 3.5, pb: 0 }}>
          Ще немає повідомлень
        </Typography>
      ) : null}
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((news) => (
            <NewsItem key={news.id} news={news} />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { image, title, link, description, postedAt, lesson } = news;
  const [openImage, setOpenImage] = useState(false);
  const [modalImage, setModalImage] = useState('');

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }} />

      <Modal
        open={openImage}
        onClose={() => setOpenImage(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack alignItems="center" justifyContent="center">
          <img src={modalImage} alt="modal lala" />
        </Stack>
      </Modal>

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Link>

        <Stack direction="row" spacing={2}>
          {lesson ? (
            <Stack spacing={2}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                <div>
                  Edmap {lesson.edmap} {lesson.name}
                </div>
                {lesson.task}
              </Typography>
              <Avatar
                variant="square"
                sx={{ width: 150, height: 'auto', alignSelf: 'center' }}
                src={lesson.image}
                onClick={() => {
                  setOpenImage(true);
                  setModalImage(lesson.image);
                }}
                onKeyDown={() => {
                  setOpenImage(true);
                  setModalImage(lesson.image);
                }}
                style={{ cursor: 'pointer' }}
              />
            </Stack>
          ) : null}
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        </Stack>

        {link ? (
          <Link href={link} target="_blank">
            {link}
          </Link>
        ) : null}
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {/* {fToNow(postedAt)} */}
        {postedAt}
      </Typography>
    </Stack>
  );
}
