import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import profile from 'src/store/profile';
// @mui
import {
  Grid,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Snackbar,
  Alert,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { CreateMessage } from 'src/sections/@dashboard/student';
import { MessageNews } from 'src/sections/@dashboard/app';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function InfoPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [messages, setMessages] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [student] = useState(profile.isTeacher ? location.state : profile.data);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(!profile.isTeacher ? profile.data.group_id : false);

  const getMessages = () => {
    fetch(
      `https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/message-api?sort=-id&filter[type]=2&filter[group_id]=${group}`,
      {
        crossDomain: true,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setMessages(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getFeedbacks = () => {
    if (!profile.isTeacher)
      fetch(
        `https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/feedback-api?sort=-id&filter[student_id]=${profile.data.id}`,
        {
          crossDomain: true,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            setFeedbacks(result);
          },
          (error) => {
            console.log(error);
          }
        );
  };

  useEffect(() => {
    if (group) {
      getMessages();
      getFeedbacks();
    }
  }, [group]);

  useEffect(() => {
    if (profile.isTeacher)
      fetch(
        `https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/sgroup-api?filter[date_end][neq]='0000-00-00'&filter[teacher_id]=${profile.data.id}`,
        {
          crossDomain: true,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            setGroups(result);
            if (result.length) setGroup(result[0].id);
          },
          (error) => {
            console.log(error);
          }
        );
  }, []);

  return (
    <>
      <Helmet>
        <title> Профіль | Lala English </title>
      </Helmet>

      <Container maxWidth="xl">
        {profile.isTeacher ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Група</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                value={group}
                label="Група"
                onChange={(event) => setGroup(event.target.value)}
              >
                {groups.map((value) => {
                  return (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        ) : null}

        <Grid container spacing={3} sx={{ 'flex-direction': profile.isTeacher ? 'row-reverse' : 'row' }}>
          {profile.isTeacher ? (
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardHeader title="Нове повідомлення для групи:" />

                <CardContent>
                  <CreateMessage
                    groupid={group}
                    type={2}
                    student={student}
                    success={() => {
                      getMessages();
                      setAlert(true);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6} lg={8}>
            <MessageNews
              title={!profile.isTeacher ? 'Останні повідомлення від Lala:' : 'Повідомлення для всієї групи:'}
              list={messages.map((message) => ({
                id: message.id,
                title: 'Lala',
                description: message.text,
                image: `/assets/images/covers/cover_5.jpg`,
                postedAt: message.date,
                link: message.link,
              }))}
            />
          </Grid>
          {!profile.isTeacher && feedbacks.length ? (
            <Grid item xs={12} md={6} lg={8}>
              <MessageNews
                title="Фідбеки для батьків:"
                list={feedbacks.map((message) => ({
                  id: message.id,
                  title: 'Lala',
                  description: message.text,
                  image: `/assets/images/covers/cover_5.jpg`,
                  postedAt: message.date,
                  link: '',
                }))}
              />
            </Grid>
          ) : null}
          <Snackbar open={alert} autoHideDuration={4000} onClose={() => setAlert(false)}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              Успішно відправлено!
            </Alert>
          </Snackbar>
        </Grid>
      </Container>
    </>
  );
}
